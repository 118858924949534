dataConfirmModal.setDefaults({
  title: 'Bestätigung',
  commit: 'OK',
  cancel: 'Abbrechen',
});

let customConfirmRemoveLink = null;
let customConfirmRemoveLinkContainer = null;

$(document).on('cocoon:before-remove', function(e) {
  if ($(this.activeElement).hasClass('custom-confirm-remove-link')) {
    e.preventDefault();
    e.stopPropagation();

    const text = this.activeElement.getAttribute('data-text') || 'Möchten Sie dieses Element wirklich löschen?';
    customConfirmRemoveLink = this.activeElement;
    customConfirmRemoveLinkContainer = e.originalEvent.detail[0];

    dataConfirmModal.confirm({
      text: text,
      onConfirm: handleCustomConfirmRemoveLink,
      onCancel: resetCustomConfirmLinkVariables,
      onHide: resetCustomConfirmLinkVariables
    });
  }
});

function handleCustomConfirmRemoveLink() {
  // Hide the container without using "display: none" to ensure form elements are included in params.
  // Required to set the correct element as default.
  $(customConfirmRemoveLinkContainer)
    .css({
      visibility: 'hidden',
      width: '0',
      height: '0'
    });

  $(customConfirmRemoveLinkContainer).find('input[id$="_destroy"]').val('1');

  $(customConfirmRemoveLink).trigger('cocoon:after-remove');
  resetCustomConfirmLinkVariables();
}

function resetCustomConfirmLinkVariables() {
  customConfirmRemoveLink = null;
  customConfirmRemoveLinkContainer = null;
}